body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}

.calculator {
  max-width: 1440px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.genre {
  width: 100%;
  display: flex;
}

.genre .male {
  width: 100%;
  background-color: blue;
}

.genre .male.active {
  border: solid thick skyblue;
}
.genre .female.active {
  border: solid thick palevioletred;
}

.genre .female {
  width: 100%;
  background-color: pink;
}

.genre input {
  opacity: 0;
}

.genre i {
  cursor: pointer;
  height: 100px;
  width: 100px;
  color: white;
}
.genre i:hover {
  opacity: 0.8;
  transition: 0.1s;
  text-shadow: aliceblue 2px 2px 10px;
}

.genre i {
  padding: 2rem 0 0 0;
}

.genre i:after {
  content: "";
  position: absolute;
  display: none;
}

.height {
  padding: 1rem;
  background-color: darkturquoise;
}
.height p {
  font-size: larger;
}

.height input {
  width: 80%;
}

.numeric-inputs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  background-color: lightcoral;
}

.numeric-inputs .input-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: x-large;
  font-weight: bold;
}

.numeric-inputs input {
  height: 5rem;
  width: 5rem;
  font-size: xx-large;
  text-align: center;
  margin: 0.5rem;
  border: solid red 2px;
  border-radius: 1rem;
}

.results {
  display: flex;
  justify-content: space-evenly;
}

.results p {
  font-size: xx-large;
}

.red p {
  color: red;
}

button {
  color: white;
  font-size: x-large;
  font-weight: bold;
  background-color: blue;
  padding: 1rem;
  cursor: pointer;
  border: none;
  width: 100%;
}

button:hover {
  opacity: 0.8;
  transition: 0.4s;
}
